import TestimonialsCards from "../components/TestimonialsCards";
import Header from "../components/header";
import bgImg from "../assets/section4/background.png";
import { useLayoutEffect,useRef } from "react";
import { Observer } from "gsap/all";
import {gsap} from "gsap";
interface MyComponentProps {
    className?: string;
    onComplete?: () => void;
    onBefore?: () => void;
}

const Section5: React.FC<MyComponentProps> = ({ className, onComplete, onBefore }) => {
  const mainRef = useRef(null);
  const wholeRef = useRef(null);


  useLayoutEffect(() => {
    if(className?.indexOf('hidden ') !== -1) {
      return;
    }
    const timeline = gsap.timeline();
    timeline.from(wholeRef.current, {
      opacity: 0.7,
    }).to(wholeRef.current, {
      opacity: 1,
      duration: 1
    });
    const observer = Observer.create({
      type: "wheel, touch, pointer",
      wheelSpeed: -1,
      tolerance: 10,
      preventDefault: true,
      target: wholeRef.current,
      onUp: (self) => {
        if (timeline.isActive()) {
          return;
        }
        onComplete && onComplete();
      },
      onDown: (self) => {
        if(timeline.isActive()) {
          return;
        }
        setTimeout(() => {
          onBefore && onBefore();
        }, 1000);
      }
    })

    return () => {
      observer.kill();
      timeline.kill();
    }
  }, []);

  return (
    <div className={className} ref={wholeRef} tabIndex={0}>
       <div className="fixed top-0 left-0 w-screen h-full" ref={wholeRef}>
        <div className="fixed w-screen h-full">
          <img src={bgImg} className="absolute w-full h-full" alt=""/>
        </div>
    
      <Header />
         <div className="container mx-auto py-20 lg:py-24 xl:py-32 2xl:py-48">  
         <section className=" w-full relative   ">
        <div>
          <h1 className="text-center text-[#bafff7] text-4xl  2xl:text-6xl mb-2 md:mb-8 xl:mb-12">
            Our Impact
          </h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3  gap-8  ">
          <div className=" text-center mt-3 ">
            <p
              style={{ textShadow: "0 0 3px #fffc" }}
              className="text-[#07edc4] text-2xl md:text-4xl"
            >
              <b>1,000,000+</b>
            </p>
            <p className="text-white font-light  text-2xl md:text-4xl ">Learners</p>
          </div>
          <div className=" text-center mt-3">
            <p
              style={{ textShadow: "0 0 3px #fffc" }}
              className="text-[#07edc4]  text-2xl md:text-4xl"
            >
              <b>40+</b>
            </p>
            <p className="text-white font-light  text-2xl md:text-4xl">
              Partner Institutions
            </p>
          </div>
          <div className="text-center mt-3">
            <p
              style={{ textShadow: "0 0 3px #fffc" }}
              className="text-[#07edc4]  text-2xl md:text-4xl"
            >
              <b>85%</b>
            </p>
            <p className="text-white font-light  text-2xl md:text-4xl">
              Placement Preference
            </p>
          </div>
        </div>
      </section>
      
        </div>
        </div>
    </div>
  )
}
export default Section5;
