import React, {useRef, useEffect, useState, useLayoutEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/header";
import centerImg from "../assets/section3/center.png";
import centerMobileImg from "../assets/section3/center-mobile.png";
import man1Img from "../assets/section3/man1.png";
import man2Img from "../assets/section3/man2.png";
import baseDoorImg from "../assets/section3/base_door.png";
import doorImg from "../assets/section3/door.png";
// import contentImg from "../assets/section3/content.png";
import contentImg from "../assets/section3/content.png";
import { gsap } from "gsap";
import {Observer} from "gsap/Observer";

interface MyComponentProps {
  className?: string;
  onComplete?: () => void;
  onBefore?: () => void;
}

const Section3: React.FC<MyComponentProps> = ({ className, onComplete, onBefore }) => {
  const man_1 = useRef(null);
  const man_2 = useRef(null);
  const title_1 = useRef(null);
  const title_2 = useRef(null);
  const wholeDoorRef = useRef(null);
  const leftDoorRef = useRef(null);
  const rightDoorRef = useRef(null);
  const contentRef = useRef(null);
  const wholeRef = useRef(null);
  const centerImgRef = useRef(null);

  const flashRef = useRef(null);

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  // Timeline 1
  const timeline1 = gsap.timeline({
    paused: true,
  });
  timeline1.to(title_1.current, { y: '-80vh', duration: 1 })
  timeline1.to(title_2.current, { y: '-80vh', duration: 1 }, '<')
  timeline1.to(man_1.current, { x: '-80vw', duration: 1 }, '<')
  timeline1.to(man_2.current, { x: '80vw', duration: 1 }, '<')
  timeline1.to(centerImgRef.current, { scale: 4, duration: 1 }, '<')
  timeline1.to(wholeDoorRef.current, { scale: (isMobileDevice() ? 3.5 : 2.5), y: (isMobileDevice() ? '-10vh' : '-10vh'), duration: 1 }, '<0.5')
  timeline1.to(leftDoorRef.current, { x: (isMobileDevice() ? '-8vw' : '-4vw'), duration: 1 }, '<0.5');
  timeline1.to(rightDoorRef.current, { x: (isMobileDevice() ? '8vw' : '4vw'), duration: 1 }, '<')
  timeline1.to(contentRef.current, { opacity: 1, duration: 1 }, '<');

  // Timeline 1 reverse
  const timeline1Reverse = gsap.timeline({
    paused: true,
  });
  timeline1Reverse.to(contentRef.current, { opacity: 0, duration: 1 })
  timeline1Reverse.to(leftDoorRef.current, { x: '0vw', duration: 1 }, '<');
  timeline1Reverse.to(rightDoorRef.current, { x: '0vw', duration: 1 }, '<')
  timeline1Reverse.to(wholeRef.current, { y: '0vh', duration: 1 }, '<')
  timeline1Reverse.to(title_1.current, { y: '0vh', duration: 1 })
  timeline1Reverse.to(title_2.current, { y: '0vh', duration: 1 }, '<')
  timeline1Reverse.to(man_1.current, { x: '0vw', duration: 1 }, '<')
  timeline1Reverse.to(man_2.current, { x: '0vw', duration: 1 }, '<')
  timeline1Reverse.to(wholeDoorRef.current, { scale: 1, y: '0vh', duration: 1 }, '<0.5')
  timeline1Reverse.to(centerImgRef.current, { scale: 1, duration: 1 }, '<')


  // Timeline 2
  const timeline2 = gsap.timeline({
    paused: true,
  });
  timeline2.to(wholeRef.current, { y: '-100vh', duration: 1 })

  useLayoutEffect(() => {
    if(className?.indexOf('hidden ') !== -1) {
      return;
    }

    const observer = Observer.create({
      type: "wheel, touch, pointer",
      wheelSpeed: -1,
      tolerance: 10,
      preventDefault: true,
      target: wholeRef.current,
      onUp: (self) => {
        if(index === 0) {
          // alert("첫번째 페이지입니다.");
          setLoading(true)
          timeline1.play().then(() => {
            setIndex(1);
            setLoading(false)
          });
        } else if (index === 1) {
          setLoading(true)
          timeline2.play().then(() => {
            setIndex(2);
            setLoading(false)
            onComplete && onComplete();
          });
        } else if (index === 2) {
          onComplete && onComplete();
        }
      },
      onDown: (self) => {
        if(loading) {
          return;
        }
        if (index === 1) {
          setLoading(true)
          timeline1Reverse.play().then(() => {
            setLoading(false);
            setIndex(0);
          });
        } else if (index <= 0) {
          setTimeout(() => {
            onBefore && onBefore();
          }, 500);
        }
      },
    })

    return () => {
      observer.kill();
      // timeline1.kill();
      // timeline1Reverse.kill();
      // timeline2.kill();
    }
  }, [index, loading]);

  return (
      <div className={className}   tabIndex={0} >
        <div className="fixed top-0 left-0 w-screen h-screen overflow-hidden" ref={wholeRef}>
          <div className="fixed w-screen h-full">
            <img src={centerImg} className="absolute w-full h-full hidden md:block" ref={centerImgRef} />
            <img src={centerMobileImg} className="absolute w-full h-full md:hidden" />
          </div>

        <Header />
        <div className="absolute w-full flex flex-col px-8 md:px-48 py-8 z-[999]  overflow-x-hidden" ref={flashRef}>
          <div className="text-[1.2rem] md:text-[2rem] text-white text-center translate-y-0" ref={title_1}>
          Get placed for roles where candidates must start thinking where A.I stops
          </div>
          <div className="text-[1rem] md:text-[1.8rem] text-[#51EAF3] text-center my-8 md:my-8" ref={title_2}>
          Receive upto 2 job offers from our partner employers who give preference to hiring candidates that demonstrate mastery over soft skills in addition to technical skills/academic degrees.
          </div>
          <div id="twoPeople" className="flex items-center justify-center gap-16 mt-auto pt-[10%] 2xl:pt-[15%]">
            <img src={man1Img} ref={man_1} className="h-[40vh] md:h-[50vh] xl:h-[30vh]" />
            <img src={man2Img} ref={man_2} className="h-[40vh] md:h-[50vh] xl:h-[30vh]" />
          </div>
        </div>
        <div className="absolute bottom-[32%] md:bottom-[19%] w-[35vw] md:w-[21vw] left-[34vw] md:left-[41vw]" ref={wholeDoorRef}>
          <img src={baseDoorImg} className="w-full h-full" />
          <img src={contentImg} className="absolute top-[4vw] md:top-[2.5vw] left-[12vw] md:left-[7vw] w-[14vw]  h-[20vw] md:w-[7vw] md:h-[12vw] opacity-0" ref={contentRef} />
          <img src={doorImg} className="absolute top-0 left-[3.5vw] w-[14vw] md:w-[7vw] h-[27.5vw] md:h-[16.5vw]" ref={leftDoorRef} />
          <img src={doorImg} className="absolute top-0 right-[3.5vw] w-[14vw] md:w-[7vw] h-[27.5vw] md:h-[16.5vw]" ref={rightDoorRef} />
        </div>
        </div>
      </div>
  )
}

export default Section3
