import React, {useRef, useEffect, useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Section1 from "./Section1";
import layerImg_1 from "../assets/layer1.png";
import layerImg_2 from "../assets/layer2.png";
import layerImg_3 from "../assets/layer3.png";
import logoTextImg from "../assets/logo-text.png";
import { gsap } from "gsap";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";

const Home = () => {
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = useState(0);
    const layer_1 = useRef(null);
    const layer_2 = useRef(null);
    const layer_3 = useRef(null);
    const logoTextRef = useRef(null);
    const wholeRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const timeline = gsap.timeline();
        timeline.to(layer_1.current, { scale: 1, opacity: 1, duration: 1 });
        timeline.to(layer_2.current, { rotate: 90, opacity: 1, duration: 1 }, '<1');
        timeline.to(layer_2.current, { rotate: -90, opacity: 1, duration: 1 }, '<1');
        timeline.to(layer_3.current, { rotate: 90, opacity: 1, duration: 1 }, '<');
        timeline.to(logoTextRef.current, { x: 0, duration: 1 }, "<")
        timeline.to(wholeRef.current, { opacity: 0, zIndex: -1, duration: 1}, '<1');
        timeline.then(() => {
            setActiveSection(1);
        });


        // gsap.to(layer_1.current, { x: '-42vw', y: '-40vh', width: '10vw', duration: 1, opacity: 0, delay: 3 });
        // gsap.to(layer_2.current, { x: '-42vw', y: '-40vh', width: '10vw', duration: 1, opacity: 0, delay: 3 });
        // gsap.to(layer_3.current, { x: '-42vw', y: '-40vh', width: '10vw', duration: 1, opacity: 0, delay: 3 });
        // gsap.to(logoTextRef.current, { x: '-20vw', y: '-50vh', duration: 1, delay: 3, opacity: 0, }).then(() => {
        //     setActiveSection(1);
        // });
        // gsap.to(wholeRef.current, { opacity: 0, zIndex: -1, duration: 1, delay: 4});

        // setTimeout(() => {
        //     navigate("/section-one")
        // }, 5000);
        return () => {
            timeline.kill();
        }
    }, [])

    return (
      <div>
          <div className="fixed w-screen h-screen bg-[#191521] flex flex-col justify-center items-center z-50" ref={wholeRef}>
              <div className="relative flex justify-center items-center">
                  <img src={layerImg_3} className="w-12 md:w-auto opacity-0" ref={layer_3} />
                  <img src={layerImg_1} className="w-12 md:w-auto absolute md:top-[4px] md:left-[8px] scale-0 opacity-0" ref={layer_1} />
                  <img src={layerImg_2} className="w-12 md:w-auto absolute md:top-[4px] md:left-[8px] opacity-0" ref={layer_2} />
              </div>
              <div className="relative flex justify-center items-center mt-6 md:mt-10">
                  <img src={logoTextImg} className="translate-x-[100vw] h-4 md:h-12" ref={logoTextRef} alt="" />
              </div>
          </div>
          { activeSection === 1 && <Section1 className="fixed top-0 left-0 w-screen h-screen" onComplete={() => setActiveSection(2)} /> }
          { activeSection === 2 && <Section2 className="fixed top-0 left-0 w-screen h-screen " onBefore={() => setActiveSection(1)} onComplete={() => setActiveSection(3)} /> }
          { activeSection === 3 && <Section3 className="fixed top-0 left-0 w-screen h-screen  " onBefore={() => setActiveSection(2)} onComplete={() => setActiveSection(4)} /> }
          { activeSection === 4 && <Section4 className="fixed top-0 left-0 w-screen h-screen " onBefore={() => setActiveSection(3)} onComplete={() => setActiveSection(5)} /> }
          { activeSection === 5 && <Section5 className="fixed top-0 left-0 w-screen h-screen " onBefore={() => setActiveSection(4)} onComplete={() => setActiveSection(6)} /> }
          { activeSection === 6 && <Section6 className="fixed top-0 left-0 w-screen h-screen" onBefore={() => setActiveSection(5)} onComplete={() => {}} /> }
          {/* <Section1 className="hidden fixed top-0 left-0 w-screen h-screen safe-bottom" />
          <Section2 className="hidden fixed top-0 left-0 w-screen h-screen" />
          <Section3 className="hidden fixed top-0 left-0 w-screen h-screen" />
          <Section4 className="hidden fixed top-0 left-0 w-screen h-screen" /> */}
      </div>
    )
}

export default Home;
