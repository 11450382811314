import TestimonialsCards from "../components/TestimonialsCards";
import Header from "../components/header";
import bgImg from "../assets/section4/background.png";
import { useLayoutEffect,useRef } from "react";
import { Observer } from "gsap/all";
import {gsap} from "gsap";
interface MyComponentProps {
    className?: string;
    onComplete?: () => void;
    onBefore?: () => void;
}

const Section5: React.FC<MyComponentProps> = ({ className, onComplete, onBefore }) => {
  const mainRef = useRef(null);
  const wholeRef = useRef(null);


  useLayoutEffect(() => {
    if(className?.indexOf('hidden ') !== -1) {
      return;
    }
    const timeline = gsap.timeline();
    timeline.from(wholeRef.current, {
      opacity: 0.7,
    }).to(wholeRef.current, {
      opacity: 1,
      duration: 1
    });
    const observer = Observer.create({
      type: "wheel, touch, pointer",
      wheelSpeed: -1,
      tolerance: 10,
      preventDefault: true,
      target: wholeRef.current,
      onUp: (self) => {
        if (timeline.isActive()) {
          return;
        }
        onComplete && onComplete();
      },
      onDown: (self) => {
        if(timeline.isActive()) {
          return;
        }
        setTimeout(() => {
          onBefore && onBefore();
        }, 1000);
      }
    })

    return () => {
      observer.kill();
      timeline.kill();
    }
  }, []);

  return (
    <div className={className} ref={wholeRef} tabIndex={0}>
       <div className="fixed top-0 left-0 w-screen h-full" ref={wholeRef}>
        <div className="fixed w-screen h-full">
          <img src={bgImg} className="absolute w-full h-full" alt=""/>
        </div>
    
      <Header />
         <div className="container mx-auto py-16 lg:py-20 xl:py-8 2xl:py-24">  
       <section className="mt-4 md:mt-8 xl:mt-12">
        <TestimonialsCards/>
        </section>
        </div>
        </div>
    </div>
  )
}
export default Section5;
