import React, {useRef, useEffect, useLayoutEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/header";
import bgImg from "../assets/section4/background.png";
import financeImg from "../assets/section4/finance.png";
import itImg from "../assets/section4/it.png";
import mediaImg from "../assets/section4/media.png";
// import program1 from '../assets/ForEmployers/mobile/SF - Finance Mobile.png'
// import program2 from '../assets/ForEmployers/mobile/SF - IT Mobile.png'
// import program3 from '../assets/ForEmployers/mobile/SF - Media Mobile.png'
import financeImgMobile from "../assets/finance-mobile.png";
import itImgMobile from "../assets/it-mobile.png";
import mediaImgMobile from "../assets/media-mobile.png";

import { Observer } from "gsap/Observer";
import { gsap } from "gsap";
import Slider from "react-slick";


interface MyComponentProps {
  className?: string;
  onComplete?: () => void;
  onBefore?: () => void;
}

const Section4: React.FC<MyComponentProps> = ({
  className,
  onComplete,
  onBefore,
}) => {
  const mainRef = useRef(null);
  const wholeRef = useRef(null);
  const [index, setIndex] = useState(0);

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    cssEase: "linear",
    centerMode: true,
    pauseOnHover: false,
  };

  useLayoutEffect(() => {
    if(className?.indexOf('hidden ') !== -1) {
      return;
    }
    const timeline = gsap.timeline();
    timeline.from(wholeRef.current, {
      opacity: 0.7
    }).to(wholeRef.current, {
      opacity: 1,
      duration: 1
    });
    const observer = Observer.create({
      type: "wheel, touch, pointer",
      wheelSpeed: -1,
      tolerance: 10,
      preventDefault: true,
      target: wholeRef.current,
      onUp: (self) => {
        if(timeline.isActive()) {
          return;
        }
        onComplete && onComplete();
        // }
      },
      onDown: (self) => {
        if(timeline.isActive()) {
          return;
        }
        setTimeout(() => {
          onBefore && onBefore();
        }, 500);
      }
    });

    return () => {
      observer.kill();
      timeline.kill();
    };
  }, []);

  return (
    <div className={className}  tabIndex={0}>
      <div className="fixed top-0 left-0 w-screen h-full" ref={wholeRef}>
        <div className="fixed w-screen h-full">
          <img src={bgImg} className="absolute w-full h-full" />
        </div>

      <Header />
      <section className="container mx-auto hidden md:block xl:py-24 2xl:py-32">
          <div>
            <Slider {...settings} >
              <div className="relative">
              <a href="/our-programs"><img src={financeImg} alt="" className="img-fluid w-full h-auto" /></a>
              </div>
              <div className=" ">
                <img
                  src={itImg}
                  alt=""
                  className="w-full h-auto img-fluid"
                />
              </div>
              <div className=" ">
                <img src={mediaImg} alt="" className="w-full h-auto img-fluid" />
              </div>
            </Slider>
          </div>
        </section>
        <section  className="container mx-auto  block md:hidden py-20">
        <div className="">
          <Slider {...settings}>
            <div className="col-span-12">
            <a href="/our-programs"><img src={financeImgMobile} alt="" className="img-fluid w-full h-auto" /></a>
            </div>
            <div className="col-span-12 ">
              <img src={itImgMobile} alt="" className="img-fluid w-full h-auto" />
            </div>
            <div className="col-span-12 ">
              <img src={mediaImgMobile} alt="" className="img-fluid w-full h-auto" />
            </div>
          </Slider>
        </div>
      </section>




   </div>
    </div>
  );
};
export default Section4;
