import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import Home from "./views/Home";
import OurProgram from "./views/OurProgram";
import WIP from "./views/WIP";
import Careers from "./views/Careers";
import PrivacyPolicy from "./views/PrivacyPolicy";
import WhitePapers from "./views/WhitePapers";
import ForEmployers from "./views/ForEmployers";
import About from "./views/About";
import {gsap} from "gsap";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import { ToastContainer } from 'react-toastify'
import { Observer } from "gsap/Observer";
import 'react-toastify/dist/ReactToastify.css';

gsap.registerPlugin([ScrollToPlugin, Observer]);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  // {
  //   path: "/section-one",
  //   element: <Section1 />,
  // },
  // {
  //   path: "/section-two",
  //   element: <Section2 />,
  // },
  // {
  //   path: "/section-three",
  //   element: <Section3 />,
  // },
  // {
  //   path: "/section-four",
  //   element: <Section4 />,
  // },
  {
    path: "/our-programs",
    element: <OurProgram />
  },
  {
    path: "/wip",
    element: <WIP />
  },
  {
    path:"/for-employers",
    element:<ForEmployers/>
  },
  {
    path:"/white-papers",
    element:<WhitePapers/>
  },
  {
    path:"/careers",
    element:<Careers/>
  },
  {
    path: "/careers",
    element: <Careers />
  },
  {
    path: "/about",
    element: <About />
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />
  },
  {
    path: "*",
    element: <Navigate to='/' replace />,
  },
]);

const App = () => {
  return (
    <>
    <ToastContainer  autoClose={2000}/>
    <RouterProvider router={router} />
    </>
  )
}

export default App
